.recording-icon {
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.8);
    transition: transform 0.3s ease;
}

.recording {
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.8);
    }
    50% {
        transform: scale(1.2);
        box-shadow: 0 0 20px rgba(255, 0, 0, 1);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 10px rgba(255, 0, 0, 0.8);
    }
}

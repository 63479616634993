/* SoundWave.css */

.wave-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 100px; */
    background-color: #f8f8f8;
    position: relative;
  }
  
  .wave-bar {
    width: 10px;
    background-color: gray;
    border-radius: 5px;
    animation: waveAnimation 1.5s infinite;
  }
  
  /* Animación de la onda */
@keyframes waveAnimation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}